#page-mora10 {
  display: flex;
  min-height: 100vh;
}

#page-mora10 main {
  flex: 1;
}

.mora10-details {
  width: 700px;
  margin: 64px auto;

  background: linear-gradient(329.54deg, #753BBD 0%, #349cd7 100%);
  border: 1.7px solid #69ff47;
  border-radius: 20px;

  overflow: hidden;
}

.mora10-details > img {
  width: 100%;
  height: 390px;
  object-fit: cover;
  border-bottom: 1px solid #69FF47;
}

.mora10-details .images {
  display: grid;
  grid-template-columns: repeat(6 ,1fr);
  column-gap: 16px;
  
  margin: 16px 40px 0;
}
 
.mora10-details .images button {
  border: 0;
  height: 88px;
  background: none;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  outline: none;
  border: 1.7px solid #69ff47;
  opacity: 0.6;
}

.mora10-details .images button.active {
  opacity: 1;
}

.mora10-details .images button img {
  width: 100%;
  height: 88px;
  object-fit: cover;
  
}

.mora10-details .mora10-details-content {
  padding: 80px;
}

.mora10-details .mora10-details-content h1 {
  color: #69ff47;
  font-size: 54px;
  line-height: 54px;
  margin-bottom: 8px;
}

.mora10-details .mora10-details-content p {
  line-height: 28px;
  color: #69ff47;
  margin-top: 24px;
}

.mora10-details .mora10-details-content .map-container {
  margin-top: 64px;
  background: #349CD7;
  border: 1.7px solid #69ff47;
  border-radius: 20px;
}

.mora10-details .mora10-details-content .map-container footer {
  padding: 20px 0;
  text-align: center;
}

.mora10-details .mora10-details-content .map-container footer a {
  line-height: 24px;
  color: #753BBD;
  text-decoration: none;
}

.mora10-details .mora10-details-content .map-container .leaflet-container {
  border-bottom: 1.7px solid #69ff47;
  border-radius: 20px;
}

.mora10-details .mora10-details-content hr {
  width: 100%;
  height: 1.7px;
  border: 0;
  background: #69ff47;
  margin: 64px 0;
}

.mora10-details .mora10-details-content h2 {
  font-size: 36px;
  line-height: 46px;
  color: #69ff47;
}

.mora10-details .mora10-details-content .open-details {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.mora10-details .mora10-details-content .open-details div {
  display: grid;
  justify-content: center;
  padding: 32px 24px;
  border-radius: 20px;
  line-height: 28px;
}

.mora10-details .mora10-details-content .open-details div svg {
  display: grid;
  justify-content: center;
  margin-bottom: 20px;
}

.mora10-details .mora10-details-content .open-details div.hour {
  background: linear-gradient(149.97deg, #753BBD 50%, #349CD7 100%);
  border: 1.7px solid #69ff47;
  color: #69ff47;
}

.mora10-details .mora10-details-content .open-details div.open-on-weekends {
  display: grid;
  justify-content: center;
  background: linear-gradient(154.16deg, #349cd7 50%, #69ff47 100%);
  border: 1.7px solid #69ff47;
  color: #753BBD;
}

.mora10-details .mora10-details-content .open-details div.open-on-weekends.dont-open {
  background: linear-gradient(154.16deg, #349CD7 5%, #753BBD 100%);
  border: 1.7px solid #69ff47;
  color: #69ff47;
}

.mora10-details .mora10-details-content .detalhes {
  display: grid;
  margin-top: 12px;
  grid-template-rows: 2fr 1fr;
  row-gap: 20px;
}

.mora10-details .mora10-details-content .detalhes div {
  display: grid;
  border-radius: 20px;
}

.mora10-details .mora10-details-content .detalhes div.linha1 {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.mora10-details .mora10-details-content .detalhes div.linha1 div.linha1coluna1 {
  background: linear-gradient(149.97deg, #753BBD 50%, #349CD7 100%);
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1.7px solid #69ff47;
  color: #69ff47;
  justify-content: center;
}

.mora10-details .mora10-details-content .detalhes div.linha1 div.linha1coluna2 {
  background: linear-gradient(149.97deg, #753BBD 50%, #349CD7 100%);
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1.7px solid #69ff47;
  color: #69ff47;
  justify-content: center;
}

.mora10-details .mora10-details-content .detalhes div.linha2.Mora10 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(154.16deg, #349cd7 50%, #69ff47 100%);
  border: 1.7px solid #69ff47;
}

.mora10-details .mora10-details-content .detalhes div.linha2.Mora10 p {
  display: flex;
  margin: 0;
  padding-left: 15px;
  color: #753BBD;
}

.mora10-details .mora10-details-content .detalhes div.linha2.Convencional {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(154.16deg, #349CD7 5%, #753BBD 100%);
  border: 1.7px solid #69ff47;
}

.mora10-details .mora10-details-content .detalhes div.linha2.Convencional p {
  display: flex;
  margin: 0;
  padding-left: 15px;
  color: #69ff47;
}

.mora10-details .mora10-details-content button.contact-button {
  margin-top: 64px;

  width: 100%;
  height: 48px;
  border: 1.7px solid #349CD7 ;
  cursor: pointer;
  background: #69ff47;
  border-radius: 20px;
  color: #349CD7;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

.mora10-details .mora10-details-content button.contact-button:hover {
  background: #349CD7;
  border: 1.7px solid #69ff47;
  color: #69FF47;
}

.mora10-details .mora10-details-content button.contact-button svg {
  margin-right: 16px;
}

.mora10-details .mora10-details-content button.contact-button:hover svg {
  background: transparent;
  fill: #69FF47; 
}


