#page-create-mora10 {
  display: flex;
}

#page-create-mora10 main {
  flex: 1;
}

form.create-mora10-form {
  width: 700px;
  margin: 64px auto;

  background: linear-gradient(329.54deg, #753BBD 0%, #349cd7 100%);
  border: 1.7px solid #69ff47;
  border-radius: 20px;

  padding: 64px 80px;

  overflow: hidden;
}

form.create-mora10-form .leaflet-container {
  margin-bottom: 40px;
  border: 1.7px solid #69ff47;
  border-radius: 20px;
}

form.create-mora10-form fieldset {
  border: 0;
}

form.create-mora10-form fieldset + fieldset {
  margin-top: 80px;
}

form.create-mora10-form fieldset legend {
  width: 100%;

  font-size: 32px;
  line-height: 34px;
  color: #69ff47;
  font-weight: 700;

  border-bottom: 1.7px solid #69ff47;
  margin-bottom: 40px;
  padding-bottom: 24px;
}

form.create-mora10-form .input-block + .input-block {
  margin-top: 24px;
}

form.create-mora10-form .input-block label {
  display: flex;
  color: #69ff47;
  margin-bottom: 8px;
  line-height: 24px;
}

form.create-mora10-form .input-block label span {
  font-size: 12px;
  color: #69ff47;
  margin-left: 24px;
  line-height: 24px;
}

form.create-mora10-form .input-block input,
form.create-mora10-form .input-block textarea {
  width: 100%;
  background: #349bd781;
  border: 1.7px solid #69ff47;
  border-radius: 20px;
  outline: none;
  color: #69FF47;
}

form.create-mora10-form .input-block input {
  height: 48px;
  padding: 0 16px;
  border: 1.7px solid #69ff47;
}

form.create-mora10-form .input-block textarea {
  min-height: 120px;
  max-height: 240px;
  resize: vertical;
  padding: 16px;
  line-height: 28px;
}

form.create-mora10-form .columns {
  columns: 2;
  margin-bottom: 30px;
}

form.create-mora10-form .input-value {
  color: #69ff47;
}

form.create-mora10-form .input-value label {
  display: flex;
  color: #69ff47;
  margin-bottom: 8px;
  line-height: 24px;
  justify-content: center;
}

form.create-mora10-form .input-value input {
  height: 48px;
  width: 200px;
  padding: 0 16px;
  background: #349bd781;
  border: 1.7px solid #69ff47;
  border-radius: 20px;
  outline: none;
  color: #69FF47;
  margin-left: 8px;
  margin-bottom: 8px;
}

form.create-mora10-form .input-block .images-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
}

form.create-mora10-form .input-block .images-container img {
  width: 100%;
  height: 96px;
  object-fit: cover;
  border-radius: 20px;
}

form.create-mora10-form .input-block .images-container .new-image {
  height: 96px;
  background: #349bd781;
  border: 1.7px dashed #69ff47;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

form.create-mora10-form .input-block input[type=file] {
  display: none;
}

form.create-mora10-form .input-block .button-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

form.create-mora10-form .input-block .button-select button {
  height: 48px;
  background: #349bd781;
  border: 1.7px solid #69FF47;
  color: #349CD7;
  cursor: pointer;
  font-size: 24px;
}

form.create-mora10-form .input-block .button-select button.active {
  background: #69FF47;
  border: 1.7px solid #349CD7;
  color: #349CD7;
}

form.create-mora10-form .input-block .button-select button:first-child {
  border-radius: 20px 0px 0px 20px;
}

form.create-mora10-form .input-block .button-select button:last-child {
  border-radius: 0 20px 20px 0;
  border-left: 0;
}

form.create-mora10-form button.confirm-button {
  margin-top: 64px;

  width: 100%;
  height: 48px;
  border: 1.7px solid #349CD7;
  cursor: pointer;
  background: #69FF47;
  border-radius: 20px;

  color:#349CD7;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

form.create-mora10-form button.confirm-button svg {
  margin-right: 16px;
}

form.create-mora10-form button.confirm-button:hover {
  background:#349CD7;
  color:#69FF47;
  transition: 0.4s;
  border: 1.7px solid #69FF47;
}
