#page-cadastro {
  display: flex;
}

#page-cadastro main {
  flex: 1;
}

form.cadastro-form {
  width: 700px;
  margin: 64px auto;

  background: linear-gradient(329.54deg, #349cd7 0%, #753BBD 85%);
  border: 1.7px solid #69ff47;
  border-radius: 20px;

  padding: 64px 80px;
  
  overflow: hidden;
}

form.cadastro-form .cadastro-label {
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  color: #69FF47;
  font-size: 24px;
}

form.cadastro-form .input-block {
  padding-bottom: 30px;
}

form.cadastro-form .input-block input {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border: 2px solid #69ff47;
  border-radius: 20px;
  outline: none;
  height: 48px;
  padding: 16px;
  color: #753BBD;
  margin-bottom: 20px;
  text-align: center;
}

form.cadastro-form .input-block input::placeholder {
  color: #349CD7;
}

button.add-button {
  margin-top: 64px;

  width: 100%;
  height: 48px;
  border: 2px solid #753BBD;
  cursor: pointer;
  background: #69FF47;
  border-radius: 20px;

  color:#753BBD;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

button.add-button:hover {
  background:#349CD7;
  color:#69FF47;
  transition: 0.4s;
  border: 1.7px solid #69FF47;
}
