#page-map {
 width: 100vw;
 height: 100vh;
 
 position: relative;
 display: flex;
}

#page-map aside {
  width: 440px;
  background: linear-gradient(329.54deg, #753BBD 0%, #349cd7 100%);
  padding: 80px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#page-map aside h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 42px;
  margin-top: 64px;
}

#page-map aside p {
  line-height: 28px;
  margin-top: 24px;
}

#page-map aside footer {
  display: flex;
  flex-direction: column;

  line-height: 24px;
}

#page-map aside footer strong {
  font-weight: 800;
}

#page-map .leaflet-container {
  z-index: 5;
}

#page-map .login {
  position: absolute;
  left: 435px;
  bottom: 40px;

  z-index: 10;

  width: 64px;
  height: 64px;
  background: #69FF47;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

#page-map .login:hover {
  background: #753BBD;
}

#page-map .login:hover svg{
  background: transparent;
  fill: #69FF47; 
}

#page-map .create-mora10 {
  position: absolute;
  right: 40px;
  bottom: 40px;

  z-index: 10;

  width: 64px;
  height: 64px;
  background: #753BBD;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

#page-map .create-mora10:hover {
  background: #69FF47;
}

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(117, 59, 189, 0.89);
  border-radius: 20px;
  box-shadow: 5px 5px 2em rgba(52,156,215, 0.9);
  display: flex;
}

#page-map .map-popup .leaflet-popup-content {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

#page-map .map-popup h1 {
  color:  #69FF47;
  font-size: 20px;
  font-family: Nunito;
  font-weight: bold;
  align-items: left; 
  margin-bottom: 0;
}

#page-map .map-popup p {
  color:  #69FF47;
  font-size: 16px;
  font-family: Nunito;
  font-weight: normal;
  display: flex;
  margin-top: 0;   
  margin-bottom: 0;
}

#page-map .map-popup .leaflet-popup-content a {
  width: 40px;
  height: 40px;
  background:  #69FF47;
  
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.3s;
}

#page-map .map-popup .leaflet-popup-content a:hover {
  background:  #349CD7;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}