#page-landing {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(329.54deg, #753BBD 0%, #349cd7 100%);

  display: flex;
  justify-content: center;
  align-items: center;
}

#page-landing .content-wrapper {
  position: relative;

  width: 100%;
  max-width: 1100px;

  height: 100%;
  max-height: 680px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10;
  background: url("../../images/landing_img.svg") no-repeat 80%;
}

#page-landing .content-wrapper main {
  max-width: 350px;
}

#page-landing .content-wrapper main h1 {
  font-size: 76px;
  font-weight: 900;
  line-height: 70px;
  
}

#page-landing .content-wrapper main p {
  margin-top: 40px;
  font-size: 24px;
  line-height: 34px;
}

.content-wrapper .location {
  position: absolute;
  right: 0;
  top: 0;

  font-size: 24px;
  line-height: 34px;

  display: flex;
  flex-direction: column;

  text-align: right;
}

.content-wrapper .location strong {
  font-weight: 800;
}

.content-wrapper .enter-app {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 250px;
  height: 80px;
  background: #69FF47;
  border-radius: 30px;
  border: 1px solid #753BBD;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  text-decoration: none;
  font-size: 24px;
  line-height: 30px;
  color: #753BBD;

  transition: background-color 0.2s;
}

.content-wrapper .enter-app:hover {
  background: #753BBD;
  color: #69FF47;
  border: 1px solid #69FF47;
}

