#page-login {
  display: flex;
}

#page-login main {
  flex: 1;
}

form.login-form {
  width: 700px;
  margin: 64px auto;

  background: linear-gradient(329.54deg, #349cd7 0%, #753BBD 85%);
  border: 1.7px solid #69ff47;
  border-radius: 20px;

  padding: 64px 80px;
  
  overflow: hidden;
}

form.login-form .logo_img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 300px;
  height: 300px;
  background: url("../../images/icon_circle_v.svg") no-repeat 50%;
}

form.login-form .input-block {
  padding-bottom: 20px;
}

form.login-form .input-block label {
  display: flex;
  color: #69ff47;
  line-height: 24px;
  margin-left: 10px;
}

form.login-form .input-block input {
  width: 100%;
  background: #ffffff;
  border: 2px solid #69ff47;
  border-radius: 20px;
  outline: none;
  height: 48px;
  padding: 16px;
  color: #753BBD;
  margin-bottom: 20px;
}

form.login-form .input-block input::placeholder {
  color: #349CD7;
}


button.confirm-button {
  margin-top: 64px;

  width: 100%;
  height: 48px;
  border: 2px solid #753BBD;
  cursor: pointer;
  background: #69FF47;
  border-radius: 20px;

  color:#753BBD;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

button.confirm-button:hover {
  background:#349CD7;
  color:#69FF47;
  transition: 0.4s;
  border: 1.7px solid #69FF47;
}

form.login-form .login-details a.add-button {
  margin-top: 14px;
  text-decoration: none;
  width: 100%;
  height: 48px;
  border: 2px solid #753BBD;
  cursor: pointer;
  background: #349CD7;
  border-radius: 20px;

  color:#753BBD;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

form.login-form .login-details a.add-button:hover {
  background:#753BBD;
  color:#69FF47;
  transition: 0.4s;
  border: 1.7px solid #69FF47;
}

form.login-form .login-details .forgot {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}  

form.login-form .login-details .forgot a {
  color: #69FF47;
  text-decoration: none;
} 

form.login-form .login-details .forgot a:hover {
  color: #753BBD;
} 

form.login-form .login-details .forgot a.pular {
  color: #69FF47;
  text-decoration-line: underline;
} 

form.login-form .login-details .forgot a.pular:hover {
  color: #753BBD;
  text-decoration-line: underline;
}